import React, { useState, useEffect } from 'react';
import { Layout, Avatar, Dropdown, Button } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';

const { Header, Content } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [time, setTime] = useState(900);
  const [isRunning] = useState(true);

  useEffect(() => {
    let interval;
    if (isRunning && time > 0) {
      interval = setInterval(() => setTime(t => t - 1), 1000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [isRunning, time]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const userMenuItems = [
    { key: '1', label: 'Profile' },
    { key: '2', label: 'Settings' },
    { key: '3', label: 'Logout' }
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar collapsed={collapsed} />
      <Layout>
        <Header style={{ background: '#3b8eb9', padding: '0 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{ color: 'white', fontSize: 16 }}
          />
          
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <span style={{ color: 'white' }}>{formatTime(time)}</span>
            <Button type="text" icon={<MailOutlined />} style={{ color: 'white' }} />
            <Dropdown menu={{ items: userMenuItems }} placement="bottomRight" trigger={['click']}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8, cursor: 'pointer' }}>
                <Avatar icon={<UserOutlined />} />
                <span style={{ color: 'white' }}>Torben</span>
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content style={{ padding: 24, backgroundColor: '#ffffff' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;