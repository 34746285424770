import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, Flex, Input, Image } from 'antd';
import { HomeOutlined, ToolOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import LogoMain from '../../images/logo-main.png';
import LogoIcon from '../../images/logo-icon.png';

const { Sider } = Layout;
const { Search } = Input;

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();

  const menuItems = [
    {
      key: 'home',
      icon: <HomeOutlined />,
      label: 'Home',
      children: [
        { key: '/', label: 'Dashboard' },
        { key: '/profile', label: 'Profile' },
        { key: '/', label: 'Settings' }
      ]
    },
    {
      key: '/',
      icon: <ToolOutlined />,
      label: 'Tools'
    },
    {
      key: '/',
      icon: <QuestionCircleOutlined />,
      label: 'Help'
    }
  ];

  const handleMenuClick = ({ key }) => {
    navigate(key);
  };

  return (
    <Sider width={280} trigger={null} collapsible collapsed={collapsed} style={{ backgroundColor: '#f8fafb' }}>
      <Flex align="center" justify="center" style={{ padding: 5, backgroundColor: '#3382b0', textAlign: 'center', minHeight: '64px' }}>
        {!collapsed ? (
          <Image src={LogoMain} preview={false} style={{ height: '50px' }} />
        ) : (
          <Image src={LogoIcon} preview={false} style={{ height: '40px' }} />
        )}
      </Flex>
      
      {!collapsed && (
        <div style={{ padding: '15px 16px', marginBottom: 16 }}>
          <h3>Hello, Torben</h3>
          <Search placeholder="Search..." style={{ marginTop: 16 }} />
        </div>
      )}

      <Menu mode="inline" items={menuItems} onClick={handleMenuClick} style={{ backgroundColor: 'transparent', border: '0 none' }} />
    </Sider>
  );
};

export default Sidebar;